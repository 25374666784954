import React from "react";
import { Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      {/* <div className="scrollToTop">
        <i className="fas fa-angle-up"></i>
      </div>
      <div className="overlay"></div>
      <div className="overlayTwo"></div> */}

      <header class="header-1">
        <div
          class="header-top headerContact"
          style={{ background: "#ee463b;" }}
        >
          <div class="container">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-12 col-12">
                <div class="ht-left">
                  <ul class="lab-ul d-flex flex-wrap justify-content-start">
                    <li class="d-flex flex-wrap align-items-center">
                      <div class="ht-add-thumb mr-3">
                        <i
                          class="fa fa-mobile-phone"
                          style={{ fontSize: "50px", color: "#ee463b" }}
                        ></i>
                      </div>
                      <div class="ht-add-content">
                        <span>
                          Dr. Tapan Chatterjee
                          <br />
                          [PRESIDENT]
                        </span>
                        <span class="d-block text-bold">+91-9434494414</span>
                      </div>
                    </li>
                    <li class="d-flex flex-wrap align-items-center">
                      <div class="ht-add-thumb mr-3">
                        <i
                          class="fa fa-mobile-phone"
                          style={{ fontSize: "50px", color: "#6abcbe" }}
                        ></i>
                      </div>
                      <div class="ht-add-content">
                        <span>
                          Dr. Gopal Dey
                          <br />
                          [WORKING PRESIDENT]
                        </span>
                        <span class="d-block text-bold">+91-9434247751</span>
                      </div>
                    </li>
                    <li class="d-flex flex-wrap align-items-center">
                      <div class="ht-add-thumb mr-3">
                        <i
                          class="fa fa-mobile-phone"
                          style={{ fontSize: "50px", color: "#ffc600" }}
                        ></i>
                      </div>
                      <div class="ht-add-content">
                        <span>
                          Shri. Debarshi Bhattacharaya
                          <br />
                          [SECRETARY]
                        </span>
                        <span class="d-block text-bold">+91-9800859956</span>
                      </div>
                    </li>
                    <li class="d-flex flex-wrap align-items-center">
                      <div class="ht-add-thumb mr-3">
                        <img src="assets/images/header/02.png" alt="email" />
                      </div>
                      <div class="ht-add-content">
                        <span>Email : </span>
                        <span class="d-block text-bold">pbvmddc@gmail.com</span>
                      </div>
                    </li>
                    <li class="d-flex flex-wrap align-items-center">
                      <div class="ht-add-thumb mr-3">
                        <img src="assets/images/header/03.png" alt="call" />
                      </div>
                      <div class="ht-add-content">
                        <span>Address : </span>
                        <span class="d-block text-bold">
                          Deshbandhu Para <br />
                          Siliguri
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-2 col-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-center">
                <div class="header-bar d-lg-none">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div class="logo">
                  <Link to={"/"}>
                    <img
                      className="headerlogo"
                      src="assets/images/logo/logo.png"
                      alt="logo"
                      style={{ background: "#FFF" }}
                    />
                  </Link>
                </div>
                <div class="ellepsis-bar d-lg-none">
                  <i class="fas fa-ellipsis-h"></i>
                </div>
              </div>
            </div>
            <div class="col-xl-10 col-12">
              <div class="header-bottom">
                <div class="header-wrapper">
                  <div class="menu-area justify-content-between w-100">
                    <ul class="menu">
                      <li>
                        <NavLink
                          to={"/"}
                          style={({ isActive }) => ({
                            color: isActive ? "#ffc600" : "#FFF",
                          })}
                        >
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <a href="#">PROJECTS</a>
                        <ul class="submenu">
                          <li>
                            <NavLink
                              to={"/events"}
                              style={({ isActive }) => ({
                                color: isActive ? "#ffc600" : "#000",
                              })}
                            >
                              EVENTS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={"/initiatives"}
                              style={({ isActive }) => ({
                                color: isActive ? "#ffc600" : "#000",
                              })}
                            >
                              INITIATIVES
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={"/upcomingprojects"}
                              style={({ isActive }) => ({
                                color: isActive ? "#ffc600" : "#000",
                              })}
                            >
                              UPCOMING PROJECTS
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink
                          to={"/ourvolunteers"}
                          style={({ isActive }) => ({
                            color: isActive ? "#ffc600" : "#FFF",
                          })}
                        >
                          Our VOLUNTEER
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/aboutus"}
                          style={({ isActive }) => ({
                            color: isActive ? "#ffc600" : "#FFF",
                          })}
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/contact"}
                          style={({ isActive }) => ({
                            color: isActive ? "#ffc600" : "#FFF",
                          })}
                        >
                          Contact
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/subscription"}
                          style={({ isActive }) => ({
                            color: isActive ? "#ffc600" : "#FFF",
                          })}
                        >
                          Subscription
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/vigyanaviksha"}
                          style={({ isActive }) => ({
                            color: isActive ? "#ffc600" : "#FFF",
                          })}
                        >
                          Vigyan Aviksha
                        </NavLink>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="cart-sidebar-area">
        <div class="top-content">
          <a href="index.html" class="logo">
            <img src="assets/images/logo/logo.png" alt="logo" />
          </a>
          <span class="side-sidebar-close-btn">
            <i class="fas fa-times"></i>
          </span>
        </div>
        <div class="bottom-content">
          <div class="cart-products">
            <h4 class="title">Shopping cart</h4>
            <div class="single-product-item">
              <div class="thumb">
                <img src="assets/images/shop/01.png" alt="shop" />
              </div>
              <div class="content">
                <h4 class="title">Color Pencil</h4>
                <div class="price">
                  <span class="pprice">$80.00</span>{" "}
                  <del class="dprice">$120.00</del>
                </div>
                <a href="#" class="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div class="single-product-item">
              <div class="thumb">
                <img src="assets/images/shop/02.png" alt="shop" />
              </div>
              <div class="content">
                <h4 class="title">Water Pot</h4>
                <div class="price">
                  <span class="pprice">$80.00</span>{" "}
                  <del class="dprice">$120.00</del>
                </div>
                <a href="#" class="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div class="single-product-item">
              <div class="thumb">
                <img src="assets/images/shop/03.png" alt="shop" />
              </div>
              <div class="content">
                <h4 class="title">Art Paper</h4>
                <div class="price">
                  <span class="pprice">$80.00</span>{" "}
                  <del class="dprice">$120.00</del>
                </div>
                <a href="#" class="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div class="single-product-item">
              <div class="thumb">
                <img src="assets/images/shop/04.png" alt="shop" />
              </div>
              <div class="content">
                <h4 class="title">Stop Watch</h4>
                <div class="price">
                  <span class="pprice">$80.00</span>{" "}
                  <del class="dprice">$120.00</del>
                </div>
                <a href="#" class="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div class="single-product-item">
              <div class="thumb">
                <img src="assets/images/shop/05.png" alt="shop" />
              </div>
              <div class="content">
                <h4 class="title">Comics Book</h4>
                <div class="price">
                  <span class="pprice">$80.00</span>{" "}
                  <del class="dprice">$120.00</del>
                </div>
                <a href="#" class="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div class="btn-wrapper text-center">
              <a href="#" class="custom-button">
                <span>Checkout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
