import React from "react";

export const Subscription = () => {
  return (
    <>
      <section
        className="page-header bg_img"
        data-background="assets/images/banner/page-header.jpg"
      >
        <div className="container">
          <div className="page-header-content">
            <h1 className="title">Subscription</h1>
            <ul className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>Subscription</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="feature-section style-2 padding-bottom padding-top pos-rel">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 pl-xl-70">
              <div className="row justify-content-center mb-30-none -mx-10">
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <h4 className="title">MEMBERSHIP</h4>
                        <p>
                          <b>1.</b> Student Membership : Rs. 5/- ( Yearly)
                          <br />
                          <b>2.</b> General Membership : Rs. 15/- (Yearly)
                          <br />
                          <b>3.</b> Life Membership : Rs. 500/- (One time)
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <h4 className="title">SUBSCRIPTION OF PERIODICALS</h4>
                        <p>
                          <b>1.</b> Kishore Bigyani - Rs. 160/- ( One Year: 6
                          books )
                          <br />
                          <b>2.</b> Jonobigyaner Istahar - Rs. 125/- ( One Year
                          : 6 books)
                          <br />
                          <b>
                            Subscription Year : From October to September ( 12
                            Month)
                          </b>
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <h4 className="title">DONATION</h4>
                        <p style={{ color: "red" }}>
                          We are not collecting any online donation
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
