import React from 'react'
import { Link } from 'react-router-dom'

const TermsAndCondition = () => {
  return (
    <><section
    className="page-header bg_img"
    data-background="assets/images/banner/page-header.jpg"
>
    <div className="container">
        <div className="page-header-content">
            <h1 className="title">Terms And Condition</h1>
            <ul className="breadcrumb">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>Terms And Condition</li>
            </ul>
        </div>
    </div>
</section>
<div className="feature-section style-2 padding-bottom padding-top pos-rel">
               
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-12 pl-xl-70">
                            <div className="row justify-content-center mb-30-none -mx-10">
                                <div className="col-sm-12 col-12">
                                    <div className="feature-item style-2">
                                        <div className="feature-inner">
                                            <div className="feature-content">


                                                <h4 className="title">TERMS AND CONDITION</h4><br />
                                                <p style={{ fontSize: 20 }}>
                                                    <b>Mission Statement:</b> SOCIETYs usually have a mission statement that outlines their purpose and goals. The mission statement may include information about the organization's values, objectives, and activities<br /><br />
                                                    <b>Governance:</b> SOCIETYs typically have a governing board or committee that oversees the organization's operations. The governance structure may include bylaws, policies, and procedures that govern the organization's decision-making and accountability<br /><br />
                                                    <b>Funding:</b>	Paschimbanga Vigyan Mancha, Darjeeling District Committee  may receive funding from   event registration fee , subscriptions of membership, subscription of books and any other entry fees for organizing any programme by Paschimbanga Vigyan Mancha, Darjeeling District Committee . If anyone wants to sponsor an event on their own accord, we welcome it. If anyone wants to give something voluntarily we accept it with pleasure. Paschimbanga Vigyan Mancha, Darjeeling District Committee will not be availing tax exemption and will not be promising tax benefit to the end user.  The terms and conditions of funding may vary depending on the source and type of funding.<br /><br />
                                                    <b>Membership:</b> SOCIETYs may have terms and conditions for accepting memberships, including minimum and maximum membership amounts, restrictions on the use of funds, and guidelines for acknowledging donors<br /><br />
                                                    <b>Volunteers:</b> SOCIETYs may rely on volunteers to help with their operations and activities. The terms and conditions for volunteering may include requirements for background checks, training, and liability waivers.<br /><br />
                                                   

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

</>
  )
}

export default TermsAndCondition