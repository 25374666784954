import React from 'react'

const AboutGridContent = () => {
  return (
    <>
      <div className="feature-section style-2 padding-bottom padding-top pos-rel">
        {/* <div className="bg-shape d-none d-lg-block">
          <img src="assets/images/feature/bg.jpg" alt="bg-shape" />
        </div> */}
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-lg-4">
              <div className="section-header style-2 text-left">
                <div className="sh-top">
                  <h6 className="cate">WHO WE ARE</h6>
                  <h3 className="title text-uppercase">
                    We’ve Funded More Then 44,000+ Projects
                  </h3>
                </div>
                <div className="sh-bottom">
                  <p>
                    Holisticly plagiarize enterprise deliverab through team
                    driven niche markets seamlessly pursuese nteroperable
                    "outside.
                  </p>
                  <a href="#" className="custom-button mt-2">
                    <span>
                      Donate Now<i className="fas fa-heart ml-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 pl-xl-70">
              <div className="row justify-content-center mb-30-none -mx-10">
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        
                        
                        <h4 className="title">ENVIRONMENT & BIODIVERSITY</h4>
                        <p>
                        <div className="feture-icon mb-4">
                          <img src="assets/images/webcont/a1.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        Environmental issues are an important agenda since the inception of PBVM. PBVM have been continuously taking different steps for the Better Environment of the state as well as conservation and protection of the biodiversity of nature & Natural Resources. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a2.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">HEALTH</h4>
                        <p>
                        Paschim Banga Vigyan Mancha is engaged in people's health movement since its inception. Our conception of health planning is 'People's Health in People's Hand.' All components of health i.e. Preventive, Promotive, Curative, Rehabilitative health care activities come under our perview. Physical, Occupational, Environmental, Mental, & Social health come under our ambit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a3.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">CHILDREN SCIENCE ACTIVITIES</h4>
                        <p>
                        Children Science Festival (CSF) is a unique and most popular program of Paschim Banga Vigyan Mancha. It is an occasion for children to get together and engage in learning. We can say that CSF is also a school - but with a difference from the usual ones. By this festival we want to create fraternal relationship among the students of all communities i.e. sensation of national integration. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a4.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">HANDS-ON EXPERIMENTS</h4>
                        <p>
                        To make science teaching joyful and interesting Hands on experiments play a pivotal role. To popularize science and to make the students innovative, Hands on experiments is now a regular programme of PBVM under Science Education desk. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a5.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">CULTURAL ACTIVITIES</h4>
                        <p>
                        Our main objective is to popularize science and to develop scientific temper among the people. To fulfill our goal cultural activities have an immense role - remembering this, Darjeeling District Committee  took initiatives for preparation and presentation cultural activities among the students round the year by the programme "TALENT SEARCH i.e. PROTIBHAR SONDHANE " 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a6.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">TECHNOLOGY APPLICATION</h4>
                        <p>
                        PBVM is very much engaged in transfering technology to the people to improve their livelihood and for popularization of science and development of scientific temper. PBVM Set up district level Science & Technology centres to facilitate the activity of 'technology transfer'.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a7.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">SKYWATCHING</h4>
                        <p>
                        Limitless universe is a point of curiosity still today. Many things are yet to be discovered. There are many superstitious believes which are originated from the ignorance of people about the universe. To meet the curiosity and to develop scientific temper Sky Watching desk was formed . Sky watching through telescope and observing celestial phenomena becomes a popular and regular programme of PBVM. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                        <img src="assets/images/webcont/a8.jpg" style={{width:100, borderRadius:100}} alt="" />
                        </div>
                        <h4 className="title">ANTI SUPERSTITION MOVEMENT</h4>
                        <p>
                        Anti-superstition demonstration is  very much effective for campaign of different issues of people's science movement. it took the pivotal role when Anti Superstition programmes are organized by PBVM on different issues.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutGridContent

