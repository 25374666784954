import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
function SponserWidgets() {
  return (
    <>
      <div
        className="sponsor-section padding-top padding-bottom bg_img"
        data-background="assets/images/sponsor/bg.jpg"
      >
        <div className="container">
          <div className="section-wrapper">
            <Swiper
              breakpoints={{
                576: {
                  width: 576,
                  slidesPerView: 3,
                },
                768: {
                  width: 768,
                  slidesPerView: 4,
                },
              }}
              modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
              loop={false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              spaceBetween={20}
              slidesPerView={3}
              
              pagination={{ clickable: true }}
              //scrollbar={{ draggable: false }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              effect={"slide"}
              cubeEffect={{
                shadow: false,
                slideShadows: false,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
            >
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/01.png" alt="sponsor" />
              </SwiperSlide>
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/02.png" alt="sponsor" />
              </SwiperSlide>
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/03.png" alt="sponsor" />
              </SwiperSlide>
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/04.png" alt="sponsor" />
              </SwiperSlide>
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/05.png" alt="sponsor" />
              </SwiperSlide>
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/06.png" alt="sponsor" />
              </SwiperSlide>
              <SwiperSlide className="sponsor-thumb">
                <img src="assets/images/sponsor/01.png" alt="sponsor" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default SponserWidgets;
