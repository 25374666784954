import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import webContent from "../include/Connection";
import ImageViewer from "react-simple-image-viewer";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
export const ViewGallery = (props) => {
  let data = useLocation();
  const projectDetails = data.state.projectDetails;
  const eventImages = data.state.projectImagesAll;
  console.log("Count:::::::::" + eventImages.length);
  return (
    <>
      <div
        class="gallery-section"
        style={{ paddingTop: "10px", paddingBottom: "20px" }}
      >
        <h2
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            textAlign: "center",
          }}
        >
          {projectDetails.title}
        </h2>
        <div class="container">
          <div class="masonary-wrapper lg-three-items">
            <PhotoProvider>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry>
                  {eventImages.length > 0 &&
                    eventImages.map((imgObj, index) => (
                      <PhotoView
                        class="img-pop"
                        src={
                          webContent.webLink +
                          "/images/gallery/" +
                          imgObj.images
                        }
                      >
                        <div
                          class="masonary-item preschool programe"
                          style={{ width: "100%" }}
                        >
                          <div class="gallery-item-3">
                            <div class="gallery-thumb">
                              <img
                                src={
                                  webContent.webLink +
                                  "/images/gallery/" +
                                  imgObj.images
                                }
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div class="gallery-content">
                              <i class="fas fa-expand"></i>
                            </div>
                          </div>
                        </div>
                      </PhotoView>
                    ))}
                </Masonry>
              </ResponsiveMasonry>
            </PhotoProvider>
          </div>
        </div>
      </div>
    </>
  );
};
