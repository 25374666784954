import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (
        <><section
            className="page-header bg_img"
            data-background="assets/images/banner/page-header.jpg"
        >
            <div className="container">
                <div className="page-header-content">
                    <h1 className="title">Privacy Policy</h1>
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </section>

            <div className="feature-section style-2 padding-bottom padding-top pos-rel">
               
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-12 pl-xl-70">
                            <div className="row justify-content-center mb-30-none -mx-10">
                                <div className="col-sm-12 col-12">
                                    <div className="feature-item style-2">
                                        <div className="feature-inner">
                                            <div className="feature-content">


                                                <h4 className="title">PRIVACY POLICY</h4><br />
                                                <p style={{ fontSize: 20 }}>
                                                    <b>1.</b>	Information Collection: Explain what types of personal information the SOCIETY collects from its staff, volunteers, donors, and beneficiaries. This can include names, contact information, financial information, and other relevant data.<br /><br />
                                                    <b>2.</b>	Information Use: Outline the purposes for which the SOCIETY collects and uses personal information. This can include processing membership, administering programs, and communicating with stakeholders.<br /><br />
                                                    <b>3.</b>	Information Disclosure: Specify under what circumstances the SOCIETY may disclose personal information to third parties, such as service providers, government agencies, or other organizations.<br /><br />
                                                    <b>4.</b>	Security Measures: Describe the security measures the SOCIETY has in place to protect personal information, such as encryption, access controls, and monitoring.<br /><br />
                                                    <b>5.</b>	Data Retention: Explain how long the SOCIETY retains personal information and the criteria used to determine retention periods.<br /><br />
                                                    <b>6.</b>	Rights of Individuals: Inform individuals of their rights regarding their personal information, such as the right to access, correct, and delete their data.<br /><br />
                                                    <b>7.</b>	Contact Information: Provide contact information for individuals to request access to their personal information or to ask questions about the SOCIETY's privacy practices.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy