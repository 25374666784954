import React from 'react'
import { Link } from 'react-router-dom'

export const CancellationAndRefund = () => {
  return (
    <><section
    className="page-header bg_img"
    data-background="assets/images/banner/page-header.jpg"
>
    <div className="container">
        <div className="page-header-content">
            <h1 className="title">Cancellation And Refund</h1>
            <ul className="breadcrumb">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>Cancellation And Refund</li>
            </ul>
        </div>
    </div>
</section>

<div className="feature-section style-2 padding-bottom padding-top pos-rel">
               
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-12 pl-xl-70">
                            <div className="row justify-content-center mb-30-none -mx-10">
                                <div className="col-sm-12 col-12">
                                    <div className="feature-item style-2">
                                        <div className="feature-inner">
                                            <div className="feature-content">


                                                <h4 className="title">CANCELLATION AND REFUND</h4><br />
                                                <p style={{ fontSize: 20 }}>
                                                    <b>Cancellation Policy:</b> Explain the circumstances under which a member may cancel their membership with the society, such as non-payment of dues or violation of society bylaws. Specify the timeline for cancellation and any fees that may apply<br /><br />
                                                    <b>Refund Policy:</b> Specify the conditions under which a member may request a refund of membership dues or other fees paid to the society. This can include situations where a member cancels their membership within a certain timeframe or where the society cancels an event or program<br /><br />
                                                    <b>Contact Information:</b>	Provide contact information for members to request cancellations or refunds or to ask questions about the society's cancellation and refund policy<br /><br />
                                                   
                                                   

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

</>
  )
}
