import { React, useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import webContent from "../include/Connection";
function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchProject = async () => {
    axios
      .post(
        webContent.webLink + "/api/tetimonials.php",
        { SUBMIT: "TESTIMONIALS",  LIMIT: 5 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        //console.log(res.data);
        setLoading(false);
        return setTestimonials(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchProject();
  }, []);
  return (
    <>
      <div
        className="clients-section style-2 padding-top bg_img mt-0"
        data-background="assets/images/client/client-bg2.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="section-header style-2 text-left">
                <div className="sh-top">
                  <span className="cate">OUR TESTIMONIALS</span>
                  <h3 className="title text-uppercase">
                    What People Say About Our Organization
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="mb-30-none -mx-10">
                <Swiper className="clients-slider2"  modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            spaceBetween={20}
            //slidesPerView={3}
            navigation={{
              nextEl: ".causes-next",
              prevEl: ".causes-prev",
            }}
            pagination={{ clickable: false }}
            //scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            effect={"slide"}
            cubeEffect={{
              shadow: false,
              slideShadows: false,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}>
              {testimonials.length>0&&testimonials.map((testimonialsObj,index)=>(
                  <SwiperSlide className="clients-item style-2">
                    <div className="clients-inner">
                      <div className="clients-review">
                        <div className="cr-top">
                          <div className="crt-head">
                            <div className="crth-left">
                              <img
                                src="assets/images/client/icon.png"
                                alt="client icon"
                              />
                            </div>
                          </div>
                          <div className="crt-body">
                            <h4 className="title">
                            {testimonialsObj.testimonialsDetails.comment}
                            </h4>
                          </div>
                        </div>
                        <div className="cr-bottom">
                          <div className="client-thumb">
                            <img
                              src={webContent.webLink+"/images/testimonial/"+testimonialsObj.testimonialsDetails.photo}
                              alt={testimonialsObj.testimonialsDetails.name}
                            />
                          </div>
                          <div className="client-info">
                            <h4 className="title">{testimonialsObj.testimonialsDetails.name}</h4>
                            <span>{testimonialsObj.testimonialsDetails.designation}</span>
                            <span className="rating">
                              (<i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                
                ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
