import React, { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section
        className="page-header bg_img"
        data-background="assets/images/banner/page-header.jpg"
      >
        <div className="container">
          <div className="page-header-content">
            <h1 className="title">contact information</h1>
            <ul className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="contact-section padding-top padding-bottom">
        <div className="container">
          <div className="contact-form-area">
            <div className="row flex-row-reverse">
              <div className="col-lg-8 mb-5 mb-lg-0">
                <h4 className="title">Leave A Message</h4>
                <form className="contact-form" id="contact_form_submit">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Your Name"
                      id="name"
                      name="name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Your Email"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Phone"
                      id="phone"
                      name="phone"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Subject"
                      id="subject"
                      name="subject"
                    />
                  </div>
                  <div className="form-group w-100">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <div className="form-group w-100 text-center">
                    <button className="custom-button">
                      <span>Send Message</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-4">
                <div className="contact-wrapper">
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/contact/01.png" alt="contact" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">Office Address</h6>
                      <p>Uma BasuVigyan Bhawan, Deshbandhu Para, Siliguri, Dist. Darjeeling, W.5.
                        Kanchanjungha Stadium, Gate No. 7, Siliguri, Dist. Darjeeling. W.D</p>

                      <b>Registration No.: 55693 of 1987 - 1988</b>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/contact/02.png" alt="contact" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">Dr. Tapan Chatterjee [PRESIDENT]</h6>
                      <p>+91-9434494414</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/contact/03.png" alt="contact" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">Send email </h6>
                      <a href="mailto:pbvmddc@gmaii.com">pbvmddc@gmaii.com</a>
                    </div>
                  </div>
                  <div className="contact-item">
                    <div className="contact-thumb">
                      <img src="assets/images/contact/04.png" alt="contact" />
                    </div>
                    <div className="contact-content">
                      <h6 className="title">Our website</h6>
                      <a href="#">scienceoave.org</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="map-section pos-rel">
        <div className="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.4312020945904!2d88.42761967494009!3d26.698668169257257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e441163f5427a3%3A0x6ad3b317d4172855!2sPaschimbanga%20Vigyan%20Manchya!5e0!3m2!1sen!2sin!4v1715335146660!5m2!1sen!2sin" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>
    </>
  );
}

export default Contact;
