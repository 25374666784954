import { React, useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import webContent from "../include/Connection";
import { Link } from "react-router-dom";
export default function VolunterWidgets() {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchProject = async () => {
    axios
      .post(
        webContent.webLink + "/api/members.php",
        { SUBMIT: "MEMBERS", TYPE: "", LIMIT: 20 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        console.log(res.data);
        setLoading(false);
        return setMembers(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchProject();
  }, []);
  return (
    <>
      <div className="clients-section volunteer padding-top padding-bottom mt-0 pos-rel">
        <div className="abs-clients-thumb">
          <img src="assets/images/bg/bg-shape2.png" alt="abs-clients-thumb" />
        </div>
        <div className="container">
          <div className="section-wrapper">
            <div className="clents-left">
              <div className="cl-content-area">
                <div className="cl-content">
                  <h3 className="title">meet our volunter</h3>
                  <p>
                    Work experience is critical to moving forward in your
                    career. But did you know that you can also gain many
                    valuable job skills through volunteering
                  </p>
                  <Link to={"/members"} className="custom-button mt-2">
                    <span>
                      OUR VOLUNTEER <i className="fas fa-heart ml-2"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="clents-right">
              <div className="cr-head">
                <span className="clients-prev active">
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="clients-next">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </div>
              <div className="cr-body mb-15-none">
                <Swiper
                  modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
                  breakpoints={{
                    400: {
                      width: 400,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                  }}
                  loop={false}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={20}
                  //slidesPerView={3}
                  navigation={{
                    nextEl: ".clients-next",
                    prevEl: ".clients-prev",
                  }}
                  showsPagination={false}
                  pagination={{ clickable: false }}
                  //scrollbar={{ draggable: false }}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  effect={"slide"}
                  cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                  }}
                >
                  {members.length > 0 &&
                    members.map((membersObj, index) => (
                      <SwiperSlide className="volunteer-item-2 m-3">
                        <div className="volunteer-thumb">
                          <div className="thumb">
                            <a href="#">
                              <img
                                src={
                                  webContent.webLink +
                                  "/images/members/" +
                                  membersObj.membersDetails.photo
                                }
                                alt="volunteer"
                              />
                            </a>
                          </div>
                          <div className="content pos-rel">
                            <h5 className="title">
                              <a href="#">{membersObj.membersDetails.name}</a>
                            </h5>
                            <span>{membersObj.membersDetails.designation}</span>
                          </div>
                        </div>
                        <div className="volunteer-content d-flex flex-wrap align-items-center justify-content-between px-4">
                          <ul className="volunteer-social justify-content-center">
                            <li>
                              <a href="#0" className="so-share">
                                <i className="fas fa-share-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="google">
                                <i className="fab fa-google-plus-g"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="dribble">
                                <i className="fab fa-dribbble"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="vimeo">
                                <i className="fab fa-vimeo-v"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="pinterest">
                                <i className="fab fa-pinterest-p"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
