import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  return (
    <>
      <footer>
        <div className="footer-top" style={{ paddingTop: '100px' }}>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="ft-bottom">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="footer-widget widget-about">
                        <h5 className="title text-uppercase">
                          Paschimbanga Vigyan Mancha
                        </h5>
                        <p>
                          Paschimbanga Vigyan Mancha is one of the largest
                          people science movement organization of our country.
                          PBVM is a voluntary organization working since 1986.
                        </p>
                        <div className="ftb-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.4312020945904!2d88.42761967494009!3d26.698668169257257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e441163f5427a3%3A0x6ad3b317d4172855!2sPaschimbanga%20Vigyan%20Manchya!5e0!3m2!1sen!2sin!4v1715335146660!5m2!1sen!2sin" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="footer-widget widget-blog">
                        <h5 className="title text-uppercase">
                          Others Link
                        </h5>
                        <ul className="footer-blog">
                          <li>

                            <div className="content">
                              <Link onClick={goToTop} to="/privacypolicy">
                                Privacy Policy
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                            <Link onClick={goToTop} to="/termsandcondition">
                                Terms and Condition
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                              <Link onClick={goToTop} to="/cancellationandrefund">
                                Cancellation and Refund
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                              <Link onClick={goToTop} to="/shippinganddelivery">
                                Shipping and Delivery
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="content">
                              <Link onClick={goToTop} to="/contact">
                                Contact Us
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pl-xl-4">
                      <div className="footer-widget widgt-form">
                        <h5 className="title text-uppercase">our NEWSLETTER</h5>
                        <p>
                          We are nonproﬁt organization supported by community
                          leaders
                        </p>
                        <form className="footer-form">
                          <input
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                          />
                          <button type="submit">
                            <span>
                              send message
                              <i className="far fa-paper-plane ml-2"></i>
                            </span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="text-center">
              <p className="mb-0">
                &copy; 2023 <Link to={"/"}>scienceoave.org</Link> - Design And
                Development By{" "}
                <Link to={"https://netdemi.com"}>netdemi.com</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
