import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import webContent from "../include/Connection";
function Events() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchProject = async () => {
    axios
      .post(
        webContent.webLink + "/api/project.php",
        { SUBMIT: "PROJECT", TYPE: "Event", LIMIT: 5 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        //console.log(res.data);
        setLoading(false);
        return setEvents(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  
  useEffect(() => {
    setLoading(true);
    fetchProject();
  }, []);
  return (
    <>
      <section
        className="page-header" style={{ backgroundImage: "uri('assets/images/banner/banner1.jpg')" }}
      >
        <div className="container">
          <div className="page-header-content">
            <h3 className="title">Our Events</h3>
            <ul className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Events</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="causes-section padding-top padding-bottom">
        <div className="container">
          {events.length > 0 ? (
            <div className="row mb-30-none justify-content-center">
              {events.length > 0 &&
                events.map((projectObj, index) => (
                  <div className="col-xl-4 col-sm-6 col-12">
                    <div className="causes-item mb-30">
                      <div className="causes-inner">
                        <div className="causes-thumb">
                          <img
                            src={
                              webContent.webLink +
                              "/images/gallery/" +
                              projectObj?.projectimages[0]?.images
                            }
                            alt="causes"
                          />

                        </div>
                        <div className="causes-content bg-white">
                          <a
                            href="#"
                            className="causes-catagiry mb-2 text-uppercase"
                          >
                            <i class="fas fa-map-marker-alt"></i>{" "}
                            {projectObj.project.address.toUpperCase()}
                          </a>
                          <h4 className="title mb-3">
                            <a href="#">
                              {projectObj.project.title.toUpperCase()}
                            </a>
                          </h4>

                          <Link to={"/gallery"} state={{projectDetails:projectObj.project,projectImagesAll:projectObj.projectimages}} className="custom-button mt-2">
                            <span>
                              View Now<i className="fas fa-heart ml-2"></i>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <h1>No Records found</h1>)}
          <ul className="default-pagination">
            <li>
              <a href="#">
                <i className="fas fa-angle-left"></i>
              </a>
            </li>
            <li>
              <a href="#">01</a>
            </li>
            <li>
              <a href="#" className="active">
                02
              </a>
            </li>
            <li>
              <a href="#">03</a>
            </li>
            <li>
              <a href="#">
                <i className="fas fa-angle-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Events;
