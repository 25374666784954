import React from 'react'
import CountUp from "react-countup";

function CounterWidgets() {
  return (
    <><div
    className="counter-section padding-top padding-bottom bg_img"
    data-background="assets/images/counter/counter-bg.jpg"
  >
    <div className="container">
      <div className="row justify-content-center mb-30-none">
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="counter-item">
            <div className="counter-thumb pos-rel">
              <div className="count-bg-shap">
                <img
                  src="assets/images/counter/shape/01.png"
                  alt="counter"
                />
              </div>
              <img src="assets/images/counter/01.png" alt="counter" />
            </div>
            <div className="counter-content">
              <div className="counter-header">
                <h2 className="title odometer" data-odometer-final="300">
                <CountUp end={2000} />
                </h2>
                <h2 className="title">+</h2>
              </div>
              <span className="cate">Total Our Volunteer</span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="counter-item">
            <div className="counter-thumb pos-rel">
              <div className="count-bg-shap">
                <img
                  src="assets/images/counter/shape/02.png"
                  alt="counter"
                />
              </div>
              <img src="assets/images/counter/02.png" alt="counter" />
            </div>
            <div className="counter-content">
              <div className="counter-header">
                <h2 className="title odometer" data-odometer-final="2300">
                  <CountUp end={100} />
                </h2>
                <h2 className="title">+</h2>
              </div>
              <span className="cate">Total Projects</span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="counter-item">
            <div className="counter-thumb pos-rel">
              <div className="count-bg-shap">
                <img
                  src="assets/images/counter/shape/03.png"
                  alt="counter"
                />
              </div>
              <img src="assets/images/counter/03.png" alt="counter" />
            </div>
            <div className="counter-content">
              <div className="counter-header">
                <h2 className="title odometer" data-odometer-final="100">
                <CountUp end={1000} />
                </h2>
                <h2 className="title">+</h2>
              </div>
              <span className="cate">Our Total Donated</span>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="counter-item">
            <div className="counter-thumb pos-rel">
              <div className="count-bg-shap">
                <img
                  src="assets/images/counter/shape/04.png"
                  alt="counter"
                />
              </div>
              <img src="assets/images/counter/04.png" alt="counter" />
            </div>
            <div className="counter-content">
              <div className="counter-header">
                <h2 className="title odometer" data-odometer-final="550">
                <CountUp end={100} />
                </h2>
                <h2 className="title">+</h2>
              </div>
              <span className="cate">Our Products & Gifts</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></>
  )
}

export default CounterWidgets