import React from "react";
import ProjectWidgets from "../widgets/ProjectWidgets";
import Testimonials from "../widgets/Testimonials";
import CounterWidgets from "../widgets/CounterWidgets";
import VolunterWidgets from "../widgets/VolunterWidgets";
import BannerWidgets from "../widgets/BannerWidgets";
import { Link } from "react-router-dom";
import { VideosWidgets } from "../widgets/VideosWidgets";

function Home() {
  return (
    <>
      <div className="cart-sidebar-area">
        <div className="top-content">
          <a href="index.html" className="logo">
            <img src="assets/images/logo/logo.png" alt="logo" />
          </a>
          <span className="side-sidebar-close-btn">
            <i className="fas fa-times"></i>
          </span>
        </div>
        <div className="bottom-content">
          <div className="cart-products">
            <h4 className="title">Shopping cart</h4>
            <div className="single-product-item">
              <div className="thumb">
                <img src="assets/images/shop/01.png" alt="shop" />
              </div>
              <div className="content">
                <h4 className="title">Color Pencil</h4>
                <div className="price">
                  <span className="pprice">$80.00</span>{" "}
                  <del className="dprice">$120.00</del>
                </div>
                <a href="#" className="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div className="single-product-item">
              <div className="thumb">
                <img src="assets/images/shop/02.png" alt="shop" />
              </div>
              <div className="content">
                <h4 className="title">Water Pot</h4>
                <div className="price">
                  <span className="pprice">$80.00</span>{" "}
                  <del className="dprice">$120.00</del>
                </div>
                <a href="#" className="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div className="single-product-item">
              <div className="thumb">
                <img src="assets/images/shop/03.png" alt="shop" />
              </div>
              <div className="content">
                <h4 className="title">Art Paper</h4>
                <div className="price">
                  <span className="pprice">$80.00</span>{" "}
                  <del className="dprice">$120.00</del>
                </div>
                <a href="#" className="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div className="single-product-item">
              <div className="thumb">
                <img src="assets/images/shop/04.png" alt="shop" />
              </div>
              <div className="content">
                <h4 className="title">Stop Watch</h4>
                <div className="price">
                  <span className="pprice">$80.00</span>{" "}
                  <del className="dprice">$120.00</del>
                </div>
                <a href="#" className="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div className="single-product-item">
              <div className="thumb">
                <img src="assets/images/shop/05.png" alt="shop" />
              </div>
              <div className="content">
                <h4 className="title">Comics Book</h4>
                <div className="price">
                  <span className="pprice">$80.00</span>{" "}
                  <del className="dprice">$120.00</del>
                </div>
                <a href="#" className="remove-cart">
                  Remove
                </a>
              </div>
            </div>
            <div className="btn-wrapper text-center">
              <a href="#" className="custom-button">
                <span>Checkout</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <BannerWidgets />
      <div className="feature-section style-2 padding-bottom padding-top pos-rel">
        
        <div className="container">
          <div className="row justify-content-center">
            
            <div className="col-lg-12 pl-xl-70">
              <div className="row justify-content-center mb-30-none -mx-10">
                <div className="col-sm-4 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                          <img
                            src="assets/images/webcont/a1.jpg"
                            style={{ width: 100, borderRadius: 100 }}
                            alt=""
                          />
                        </div>
                        <h4 className="title">ENVIRONMENT & BIODIVERSITY</h4>
                        <p>
                          Environmental issues are an important agenda since the
                          inception of PBVM. PBVM have been continuously taking
                          different steps for the Better Environment of the
                          state as well as conservation and protection of the
                          biodiversity of nature & Natural Resources.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                          <img
                            src="assets/images/webcont/a2.jpg"
                            style={{ width: 100, borderRadius: 100 }}
                            alt=""
                          />
                        </div>
                        <h4 className="title">HEALTH</h4>
                        <p>
                          Paschim Banga Vigyan Mancha is engaged in people's
                          health movement since its inception. Our conception of
                          health planning is 'People's Health in People's Hand.'
                          All components of health i.e. Preventive, Promotive,
                          Curative, Rehabilitative health care activities come
                          under our perview. Physical, Occupational,
                          Environmental, Mental, & Social health come under our
                          ambit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="feature-item style-2">
                    <div className="feature-inner">
                      <div className="feature-content">
                        <div className="feture-icon mb-4">
                          <img
                            src="assets/images/webcont/a3.jpg"
                            style={{ width: 100, borderRadius: 100 }}
                            alt=""
                          />
                        </div>
                        <h4 className="title">CHILDREN SCIENCE ACTIVITIES</h4>
                        <p>
                        Children Science Festival (CSF) is a unique and most popular program of Paschim Banga Vigyan Mancha. It is an occasion for children to get together and engage in learning. We can say that CSF is also a school - but with a difference from the usual ones. By this festival we want to create fraternal relationship among the students of all communities i.e. sensation of national integration. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="helps-section">
        <div className="container-fluid p-lg-0">
          <div className="row no-gutters">
            <div className="col-xl-6 col-12">
              <div className="helps-left-part">
                <div className="row justify-content-xl-end justify-content-center no-gutters">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="helps-item theme-color-bg">
                      <div className="helps-inner">
                        <div className="helps-content">
                          <div className="helps-icon mb-4">
                            <img src="assets/images/helps/01.png" alt="" />
                          </div>
                          <h3>
                            2000 <sup>+</sup>
                          </h3>
                          <h4 className="title">Our Total Volunteers</h4>
                          <p>
                            Our organization have more then 2000 active volunteers. Our volunteering is an excellent way to give back to you support.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="helps-item">
                      <div className="helps-inner">
                        <div className="helps-content">
                          <div className="helps-icon mb-4">
                            <img src="assets/images/helps/02.png" alt="" />
                          </div>
                          <h3>
                            100 <sup>+</sup>
                          </h3>
                          <h4 className="title">Our Total Projects</h4>
                          <p>
                            We are dove 100+ projects all over the city. We help organizations plan, execute, and complete their projects successfully
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            <VideosWidgets />
          </div>
        </div>
      </div>
      <ProjectWidgets />
      <Testimonials />
      {/* <div className="project-section style-2">
        <div className="container">
          <div className="row justify-content-center no-gutters">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="project-item style-2">
                <div className="project-inner mb-0">
                  <div className="project-thumb">
                    <img src="assets/images/project/01.jpg" alt="project" />
                  </div>
                  <div className="project-content">
                    <a href="#">
                      <h4 className="title">Food For Poor Children</h4>
                    </a>
                    <a href="#" className="text-btn">
                      read more +
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="project-item style-2">
                <div className="project-inner mb-0">
                  <div className="project-thumb">
                    <img src="assets/images/project/02.jpg" alt="project" />
                  </div>
                  <div className="project-content">
                    <a href="#">
                      <h4 className="title">Food For Poor Children</h4>
                    </a>
                    <a href="#" className="text-btn">
                      read more +
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="project-item style-2">
                <div className="project-inner mb-0">
                  <div className="project-thumb">
                    <img src="assets/images/project/03.jpg" alt="project" />
                  </div>
                  <div className="project-content">
                    <a href="#">
                      <h4 className="title">Food For Poor Children</h4>
                    </a>
                    <a href="#" className="text-btn">
                      read more +
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="project-item style-2">
                <div className="project-inner mb-0">
                  <div className="project-thumb">
                    <img src="assets/images/project/04.jpg" alt="project" />
                  </div>
                  <div className="project-content">
                    <a href="#">
                      <h4 className="title">Food For Poor Children</h4>
                    </a>
                    <a href="#" className="text-btn">
                      read more +
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <FaqWidgets /> */}
      <CounterWidgets />
      <VolunterWidgets />
      {/* <SponserWidgets /> */}
      {/* <NewsWidgets /> */}
    </>
  );
}

export default Home;
