import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Header from "./include/Header";
import Footer from "./include/Footer";
import About from "./components/About";
import Events from "./components/Events";
import Initiatives from "./components/Initiatives";
import UpcomingProjects from "./components/UpcomingProjects";
import { Volunteers } from "./components/Volunteers";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndCondition from "./components/TermsAndCondition";
import { CancellationAndRefund } from "./components/CancellationAndRefund";
import { ShippingAndDelivery } from "./components/ShippingAndDelivery";
import { Subscription } from "./components/Subscription";
import { ViewGallery } from "./components/ViewGallery";
import { VigyanAviksha } from "./components/VigyanAviksha";
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />        
        <Route path="/aboutus" element={<About />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<ViewGallery />} />
        <Route path="/initiatives" element={<Initiatives />} />
        <Route path="/ourvolunteers" element={<Volunteers />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/vigyanaviksha" element={<VigyanAviksha />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/upcomingprojects" element={<UpcomingProjects />} />
        <Route path="/termsandcondition" element={<TermsAndCondition />} />
        <Route path="/shippinganddelivery" element={<ShippingAndDelivery />} />
        <Route path="/cancellationandrefund" element={<CancellationAndRefund />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
