import React from 'react'
import { Link } from 'react-router-dom'

export const ShippingAndDelivery = () => {
  return (
    <>
    <section
    className="page-header bg_img"
    data-background="assets/images/banner/page-header.jpg"
>
    <div className="container">
        <div className="page-header-content">
            <h1 className="title">Shipping And Delivery</h1>
            <ul className="breadcrumb">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>Shipping And Delivery</li>
            </ul>
        </div>
    </div>
</section><div className="feature-section style-2 padding-bottom padding-top pos-rel">
               
               <div className="container">
                   <div className="row justify-content-center">

                       <div className="col-lg-12 pl-xl-70">
                           <div className="row justify-content-center mb-30-none -mx-10">
                               <div className="col-sm-12 col-12">
                                   <div className="feature-item style-2">
                                       <div className="feature-inner">
                                           <div className="feature-content">


                                               <h4 className="title">SHIPPING AND DELIVERY</h4><br />
                                               <p style={{ fontSize: 20 }}>
                                                   <b>Note:</b> Society does not have any Shipping And Delivery system<br /><br />
                                                  

                                               </p>
                                           </div>
                                       </div>
                                   </div>
                               </div>

                           </div>
                       </div>
                   </div>
               </div>
           </div>

    </>
  )
}
