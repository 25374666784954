const webContent = {
  //webLink: "http://192.168.0.4/pspngo",
  webLink: "https://scienceoave.org",
  logo: "assets/images/logo.png",
  banner: {
    banner1: "assets/images/banner1.jpg",
    banner2: "assets/images/banner2.jpg",
  },
};
export default webContent;
