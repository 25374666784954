import { React, useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import webContent from "../include/Connection";
function ProjectWidgets() {
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchProject = async () => {
    axios
      .post(
        webContent.webLink + "/api/project.php",
        { SUBMIT: "PROJECT", TYPE: "Event", LIMIT: 5 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        //console.log(res.data);
        setLoading(false);
        return setProject(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchProject();
  }, []);
  return (
    <>
      <div className="causes-section style-2 padding-top padding-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-header text-left">
                <span className="cate">WE HELP AROUND THE WORLD</span>
                <h3 className="title text-uppercase">Introduse Our Campains</h3>
                <span className="causes-nav causes-prev">
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="causes-nav causes-next active">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </div>
            </div>
          </div>
          <Swiper
            breakpoints={{
              576: {
                width: 576,
                slidesPerView: 1,
              },
              768: {
                width: 768,
                slidesPerView: 2,
              },
            }}
            modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            spaceBetween={20}
            //slidesPerView={3}
            navigation={{
              nextEl: ".causes-next",
              prevEl: ".causes-prev",
            }}
            pagination={{ clickable: false }}
            //scrollbar={{ draggable: false }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            effect={"slide"}
            cubeEffect={{
              shadow: false,
              slideShadows: false,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
          >
            {project.length > 0 &&
              project.map((projectObj, index) => (
                <SwiperSlide className="causes-item">
                  <div className="causes-inner">
                    <div className="causes-thumb">
                      <img src={webContent.webLink+"/images/gallery/"+projectObj?.projectimages[0]?.images} alt={projectObj.project.title.toUpperCase()} />
                      {/* <div className="causes-progress">
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                          <div className="causes-pro-left text-center">
                            <h6>Raised</h6>
                            <h6>
                              <span>$70,000</span>
                            </h6>
                          </div>
                          <div className="causes-pro-mid">
                            <div className="text-center skill-item">
                              <div
                                className="pie"
                                data-pie='{ "index": 1, "round": true, "percent": 60, "colorSlice": "#EE463B", "size": 60, "fontWeight": 700 }'
                              ></div>
                            </div>
                          </div>
                          <div className="causes-pro-right text-center">
                            <h6>Gold</h6>
                            <h6>
                              <span>$8.00.000</span>
                            </h6>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="causes-content bg-white">
                      <a
                        href="#"
                        className="causes-catagiry mb-2 text-uppercase"
                      >
                       <i class="fas fa-map-marker-alt"></i> {projectObj.project.address.toUpperCase()}
                      </a>
                      <h4 className="title mb-3">
                        <a href="#">{projectObj.project.title.toUpperCase()}</a>
                      </h4>
                      {/* <p>
                        ProfesSona leverage existing error free exper iences
                        from high quality supply chains create Energistically
                        monetize virtual human.
                      </p> */}
                      <a href="#" className="custom-button mt-2">
                        <span>
                          View Now {projectObj.project.id.toUpperCase()}<i className="fas fa-heart ml-2"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        {/* <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div> */}
      </div>
    </>
  );
}

export default ProjectWidgets;
