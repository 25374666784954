import { React, useEffect, useState } from "react";
import axios from "axios";
import webContent from "../include/Connection";
import { Link } from "react-router-dom";
export const Volunteers = () => {
    const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchProject = async () => {
    axios
      .post(
        webContent.webLink + "/api/members.php",
        { SUBMIT: "MEMBERS", TYPE: "", LIMIT: 20 },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        console.log(res.data);
        setLoading(false);
        return setMembers(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchProject();
  }, []);
    return (
        <>
        <section
        className="page-header bg_img"
        data-background="assets/images/banner/page-header.jpg"
      >
        <div className="container">
          <div className="page-header-content">
            <h1 className="title">OUR VOLUNTEER</h1>
            <ul className="breadcrumb">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>Our Volunteers</li>
            </ul>
          </div>
        </div>
      </section>
        <section className="volunteer-section padding-bottom bg_img" style={{paddingTop:50}} data-background="assets/images/bg/volunteer-bg.jpg">
            <div className="container">
                
                <div className="row justify-content-center mb-30-none">
                {members.length > 0 &&
                    members.map((membersObj, index) => (
                    <div className="col-xl-4 col-sm-6">
                        <div className="volunteer-item-2">
                            <div className="volunteer-thumb">
                                <div className="thumb">
                                    <a href="volunteer-single.html">
                                        <img src={
                                  webContent.webLink +
                                  "/images/members/" +
                                  membersObj.membersDetails.photo
                                } alt="volunteer" />
                                    </a>
                                </div>
                                <div className="content pos-rel">
                                    <h5 className="title"><a href="#">{membersObj.membersDetails.name}</a></h5>
                                    <span>{membersObj.membersDetails.designation}</span>
                                </div>
                            </div>
                            <div className="volunteer-content d-flex flex-wrap align-items-center justify-content-between px-4">
                                <ul className="volunteer-social justify-content-center">
                                    <li>
                                        <a href="#0" className="so-share"><i className="fas fa-share-alt"></i></a>
                                    </li>
                                    <li>
                                        <a href="#" className="google"><i className="fab fa-google-plus-g"></i></a>
                                    </li>
                                    <li>
                                        <a href="#" className="dribble"><i className="fab fa-dribbble"></i></a>
                                    </li>
                                    <li>
                                        <a href="#" className="vimeo"><i className="fab fa-vimeo-v"></i></a>
                                    </li>
                                    <li>
                                        <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    ))}
                    
                </div>
            </div>
        </section></>
    )
}
