import { React, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import axios from "axios";
import webContent from "../include/Connection";
const BannerWidgets = () => {
    const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const bannerImages = async () => {
    axios
      .post(
        webContent.webLink + "/api/banner.php",
        { SUBMIT: "BANNER",},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        //console.log(res.data);
        setLoading(false);
        return setBanner(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    bannerImages();
  }, []);
  return (
    <><Swiper
       
        
    modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
    loop={true}
    autoplay={{
      delay: 5000,
      disableOnInteraction: false,
    }}
    spaceBetween={20}
    //slidesPerView={3}
    navigation={{
      nextEl: ".causes-next",
      prevEl: ".causes-prev",
    }}
    pagination={{ clickable: false }}
    //scrollbar={{ draggable: false }}
    onSlideChange={() => console.log("slide change")}
    onSwiper={(swiper) => console.log(swiper)}
    effect={"slide"}
    cubeEffect={{
      shadow: false,
      slideShadows: false,
      shadowOffset: 20,
      shadowScale: 0.94,
    }}
  >
    
    {banner.length>0&&banner.map((bannerObj,index)=>(
    <SwiperSlide className="banner-slider">
      <img src={webContent.webLink+"/images/banner/"+bannerObj.bannerDetails.images} style={{width:"100%"}} />
    </SwiperSlide>
    ))}
    {/* <SwiperSlide className="banner-slider">
      <img src="assets/images/banner/banner1.jpg" style={{width:"100%"}} />
    </SwiperSlide> */}
    {/* <SwiperSlide className="container banner-slider" style={{
      backgroundImage: "url(" + "assets/images/banner/banner1.jpg" + ")",
      backgroundPosition: "center",
      paddingTop: 20,
      paddingBottom: 20,maxWidth:'100%',height:455
    }}>
      <div className="d-flex justify-content-end flex-row-reverse">
       
        <div className="banner-content style-2">
          <h3 className="cate">Organized By</h3>
          <h1 className="title">
            Paschimbanga Vigyan Mancha
            <br />
            <span className="theme-two">Darjeeling District Committee</span>
          </h1>
          <p>
            Paschimbanga Vigyan Mancha is one of the largest people science
            movement organization of our country. PBVM is a voluntary
            organization working since 1986
          </p>
          
        </div>
      </div>
    </SwiperSlide> */}
    
  </Swiper></>
  )
}

export default BannerWidgets