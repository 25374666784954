import React from "react";
import SponserWidgets from "../widgets/SponserWidgets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import AboutGridContent from "../widgets/AboutGridContent";
import CounterWidgets from "../widgets/CounterWidgets";
function About() {
  return (
    <>
      <section
        className="page-header bg_img"
        data-background="assets/images/banner/page-header.jpg"
      >
        <div className="container">
          <div className="page-header-content">
            <h1 className="title">About Us</h1>
            <ul className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>About</li>
            </ul>
          </div>
        </div>
      </section>
      <AboutGridContent />
      <CounterWidgets />
      {/* <SponserWidgets /> */}
      {/* <div className="clients-section">
        <div className="container">
          <div className="section-wrapper">
            <div className="clents-left pos-rel">
              <div className="abs-clients-thumb">
                <img
                  src="assets/images/client/client-bg6.jpg"
                  alt="client thumb"
                />
              </div>
              <div className="cl-content-area">
                <div className="cl-content">
                  <h6 className="cate">TESTIMONIALS</h6>
                  <h3 className="title mb-sm-5">
                    What People Say About Our Company
                  </h3>
                  <a href="#" className="custom-button mt-2">
                    <span>
                      all testimonials<i className="fas fa-heart ml-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="clents-right">
              <div className="cr-head">
                <p>
                  We offer security solutions and cost effective service for our
                  client are safe and secure in any situation.
                </p>
                <span className="clients-prev active">
                  <i className="fas fa-arrow-left"></i>
                </span>
                <span className="clients-next">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </div>
              <div className="cr-body">
                <Swiper
                  breakpoints={{
                    576: {
                      width: 576,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                  }}
                  showsPagination={false}
                  modules={[Navigation, Pagination, A11y, EffectCube, Autoplay]}
                  loop={false}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={20}
                  //slidesPerView={3}
                  navigation={{
                    nextEl: ".clients-next",
                    prevEl: ".clients-prev",
                  }}
                  pagination={{ clickable: true }}
                  //scrollbar={{ draggable: false }}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  effect={"slide"}
                  cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                  }}
                >
                  <SwiperSlide className="clients-item">
                    <div className="clients-inner">
                      <div className="clients-review">
                        <div className="cr-top">
                          <div className="crt-head">
                            <div className="crth-left">"</div>
                            <div className="crth-right">
                              <p className="rating">
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                              </p>
                            </div>
                          </div>
                          <div className="crt-body">
                            <h4 className="title">
                              Ueames redefine progresve human capita where
                              orentd supply chalins reconcpuaze adaptve are
                              enable seamlui schemas create for.
                            </h4>
                          </div>
                        </div>
                        <div className="cr-bottom">
                          <div className="client-thumb">
                            <img
                              src="assets/images/client/01.jpg"
                              alt="clients"
                            />
                          </div>
                          <div className="client-info">
                            <h4 className="title">Michael Smeth</h4>
                            <span>UI Designer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="clients-item">
                    <div className="clients-inner">
                      <div className="clients-review">
                        <div className="cr-top theme-seven-bg">
                          <div className="crt-head">
                            <div className="crth-left">"</div>
                            <div className="crth-right">
                              <p className="rating">
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                              </p>
                            </div>
                          </div>
                          <div className="crt-body">
                            <h4 className="title">
                              Ueames redefine progresve human capita where
                              orentd supply chalins reconcpuaze adaptve are
                              enable seamlui schemas create for.
                            </h4>
                          </div>
                        </div>
                        <div className="cr-bottom">
                          <div className="client-thumb">
                            <img
                              src="assets/images/client/02.jpg"
                              alt="clients"
                            />
                          </div>
                          <div className="client-info">
                            <h4 className="title">John Harvard</h4>
                            <span>UX Designer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="clients-item">
                    <div className="clients-inner">
                      <div className="clients-review">
                        <div className="cr-top theme-three-bg">
                          <div className="crt-head">
                            <div className="crth-left">"</div>
                            <div className="crth-right">
                              <p className="rating">
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                              </p>
                            </div>
                          </div>
                          <div className="crt-body">
                            <h4 className="title">
                              Ueames redefine progresve human capita where
                              orentd supply chalins reconcpuaze adaptve are
                              enable seamlui schemas create for.
                            </h4>
                          </div>
                        </div>
                        <div className="cr-bottom">
                          <div className="client-thumb">
                            <img
                              src="assets/images/client/01.jpg"
                              alt="clients"
                            />
                          </div>
                          <div className="client-info">
                            <h4 className="title">Michael Smeth</h4>
                            <span>UI Designer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="clients-item">
                    <div className="clients-inner">
                      <div className="clients-review">
                        <div className="cr-top theme-color-bg">
                          <div className="crt-head">
                            <div className="crth-left">"</div>
                            <div className="crth-right">
                              <p className="rating">
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                              </p>
                            </div>
                          </div>
                          <div className="crt-body">
                            <h4 className="title">
                              Ueames redefine progresve human capita where
                              orentd supply chalins reconcpuaze adaptve are
                              enable seamlui schemas create for.
                            </h4>
                          </div>
                        </div>
                        <div className="cr-bottom">
                          <div className="client-thumb">
                            <img
                              src="assets/images/client/02.jpg"
                              alt="clients"
                            />
                          </div>
                          <div className="client-info">
                            <h4 className="title">John Harvard</h4>
                            <span>UX Designer</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      
    </>
  );
}

export default About;
