import React from "react";

export const VigyanAviksha = () => {
  return (
    <>
      <section
        className="page-header bg_img"
        data-background="assets/images/banner/page-header.jpg"
      >
        <div className="container">
          <div className="page-header-content">
            <h1 className="title">Vigyan Aviksha</h1>
            <ul className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>Vigyan Aviksha</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="feature-section style-2 padding-bottom padding-top pos-rel">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 pl-xl-70">
              <div class="causes-inner">
                <div
                  class="causes-content bg-white"
                  style={{ padding: "10px" }}
                >
                  <h4 class="title mb-3">
                    <a href="#">ONLINE REGISTRATION</a>{" "}
                    <img
                      src="assets/images/new-star.gif"
                      style={{ width: "80px" }}
                    />
                  </h4>
                  <a
                    href="#"
                    class="custom-button mt-2"
                  >
                    <span>
                      CLICK HERE<i class="fas fa-heart ml-2"></i>
                    </span>
                  </a>
                  <div className="">
                    <b>Any Queries , contact :</b> 9434379536, 8900537400,
                    9434247751, 9800859956
                    <br />
                    <br />
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <img
                        src="assets/images/1.jpeg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <img
                        src="assets/images/2.jpeg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <img
                        src="assets/images/3.jpeg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <img
                        src="assets/images/4.jpeg"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-xl-70">
              <div style={{ textAlign: "left" }}>
                <b> Acharya Prafullya Ch. Roy Vigyan Aviksha</b>
                <br />
                <b>Pashimbanga Vigyan Mancha - Darjeeling District Committee</b>
                <br />
                <b>Vigyan Aviksha</b> - not only a Science Test but also it is a
                unique thought that was designed to promote science
                consciousness, inquisitiveness and make scientific temperament
                among the students community. It is not a mere competitive or
                other kind of examination as usual but it is an effort to
                develop a path for students to adopt culture of science.
                <br />
                Students of Classes III to X of Bengali, English and Hindi
                mediums of Siliguri sub-division and adjoining Jalpaiguri
                district have been screened for Vigyan Aviskha. <br />
                <b>Subjects :</b> Physics, Chemistry, Life science, Mathematics,
                Computer, Environment, and General intelligence.
                <br />
                <b>Question Type :</b> Objective. <br />
                <b>Answer Style :</b> OMR sheet will be provided for multiple
                choice question examinations to detect answers.
                <br />
                <b>Date of Examination :</b> 29th September, 2024 , Sunday.
                <br />
                <b>Last date of submission of Registration :</b> 14th August,
                2024.
                <br />
                <b>Student Enrolment :</b>
                In order to enrol, students have to submit the information to
                their school head or enrol through online registration. For
                online registration , click the link ONLINE REGISTRATION.
              </div>
            </div>
            <div className="col-lg-12 pl-xl-70"></div>
            <div className="col-lg-12 pl-xl-70">
              <div class="causes-inner">
                <div
                  class="causes-content bg-white"
                  style={{ padding: "10px" }}
                >
                  <h4 class="title mb-3">
                    <a href="#">VIEW RESULT</a>
                  </h4>
                  <a href="#" class="custom-button mt-2">
                    <span>
                      VIEW NOW<i class="fas fa-heart ml-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
