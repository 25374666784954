import { React, useEffect, useState } from "react";
import axios from "axios";
import webContent from "../include/Connection";
import { Link } from "react-router-dom";
export const VideosWidgets = () => {
  const [videos, setVideoBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const videoImages = async () => {
    axios
      .post(
        webContent.webLink + "/api/videosApi.php",
        { SUBMIT: "VIDEOS_API" },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        console.log(res.data);
        setLoading(false);
        setVideoBanner(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    videoImages();
  }, []);
  return (
    <>
      <div className="col-xl-6 col-12">
        <div
          className="helps-right-part wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".1s"
        >

        {videos.length>0&&videos.map((ovdObj,index)=>(
          <div className="video-area">
            <img src={webContent.webLink+"/images/videos/"+ovdObj.videoDetails.image} />
            <Link target="_blank" to={ovdObj.videoDetails.videolink} className="video-button popup">
              <i className="flaticon-play"></i>
            </Link>
          </div>
          ))}
        </div>
      </div>
    </>
  );
};
